import * as icons from './govicons.json';
import logo from './Gov-Icons-Logomark.png';

function App() {
  const GOVICONS_VERSION = '1.6.0';
  const TOTAL_ICONS = icons.icons.length;

  return (
    <>
      <section className="hero">
        <div className="intro">
          <h1>Gov Icons</h1>
          <div className="underline"></div>
          <p>
            Free, open-sourced icon font and CSS toolkit with {TOTAL_ICONS}{' '}
            government themed icons, for easy scalable vector graphics.
          </p>
          <p className="meta">
            {TOTAL_ICONS} Icons • Version {GOVICONS_VERSION} • Created by{' '}
            <a href="https://540.co">540</a>
          </p>
          <a
            className="btn"
            href="https://github.com/540co/govicons/archive/master.zip"
          >
            Download Now
          </a>{' '}
          <a className="btn" href="https://github.com/540co/govicons">
            View on GitHub
          </a>
        </div>

        <img
          className="circle"
          src={logo}
          width="275px"
          height="275px"
          alt="Gov Icons Logo"
        />
      </section>
      <section className="container">
        <div className="getting-started" style={{ marginLeft: '2rem' }}>
          <h2>Getting Started</h2>
          <p style={{ marginTop: '1rem' }}>
            To use Gov Icons, place the main CSS files in your CSS directory,
            and the font files in a "font" directory on the same folder level as
            the CSS director. Once you've done that, all you need to do to
            reference an icon in your HTML is type{' '}
            <code>&lt;i class="gi gi-tank"&gt;&lt;/i&gt;</code>
          </p>
        </div>
        <h2 style={{ marginTop: '2rem', marginLeft: '2rem' }}>Icons</h2>
        <div className="icon-container">
          {icons.selection.map((icon) => (
            <div className="card-container" key={`${icon.name}`}>
              <div className="card">
                <i className={`gi gi-${icon.name}`.split(',')[0]}></i>
              </div>
              <p className="icon-name">{`${icon.name}`.split(',')[0]}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default App;
